/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

// ion-card{--background:#fff !important;}
.separator {
    display: flex;
    align-items: center;
    // text-align: center;
  }
  
  .separator::before,
  .separator::after {
    content: '';
    flex: 1;
    border-bottom: 1px solid #000 !important;
  }
  
  .separator:not(:empty)::before {
    margin-right: .25em;
  }
  
  .separator:not(:empty)::after {
    margin-left: .25em;
  }
  .p-0{padding-inline-start: 0;
    padding-inline-end: 0;}
    .fw-900{font-weight: 900;}

    .back{--background:#fff !important;}
    .card1 {--background: transparent !important;}
    .ion-item1 {--background:#009688; --color:#fff !important}
ion-toolbar{--background:#424cbf; color:#fff;}
ion-menu-button, ion-back-button{--color:#fff;}
// ion-item{--background:#fff;}
ion-thumbnail img{--background: #fff !important;}
ion-card{margin-top: 10px !important;}
.blue{color:#424cbf;}
.font-10{font-size: 10px !important;}
.font-13{font-size: 13px !important;;}
.font-14{font-size: 14px !important;;}
.font-16{font-size: 16px !important;}

.height-24{--min-height: 24px;}
.mb-0{margin-bottom: 0!important;}
.mt-0{margin-top:0!important;}
.mt-15{margin-top: 15px;}
.p-l{padding-inline-start: 15px;}
.font-12{font-size: 12px;}
.pb-0{padding-bottom: 0 !important;}
.filter{position: relative; top: 5px; left: 2px;}
.font-700{font-weight: 700;}
.light{color:#9e9e9e !important;}
.text-center{text-align: center;}
.border-top{border-top: 1px solid #e5e5e5;}
.width-50{width:50%;}
.text-center{text-align: center;}
.m-auto{margin: auto;}
.blue{color:#424cbf !important;}
.mt-20{margin-top: 20px;}
.mb-20{margin-bottom: 20px;}

.signature-pad{background-color: #fff;}

ion-item.h-57 {
  --min-height: 57px;
}